import React from 'react';
import "../../styles/components/_pillarsTonye.scss";
import Tonye3D from '../_pages/home/3DBackground/Tonye3D';

function PillarsTonye(props) {
  const text = props.text || "TONYE";
  const upperCase = props.upperCase || false;
  const heavyMode = props.heavyMode || false;
  const showMask = props.showMask || false;

  return (
    <div className="canvas-wrapper">
      <div className={`tonye-mask ${showMask ? "showMask" : ""}`}>
        <span className={`tonye-mask-text ${heavyMode ? "heavyMode" : ""}`}>
          <>
            {text.split("").map((letter, index) => (
              <span className={letter.toLowerCase()} key={index}><b>{upperCase ? letter.toUpperCase() : letter}</b></span>
            ))}
          </>
          {<div className="wipe" />}
        </span>
      </div>
      {props.showDiamonds && <Tonye3D overlay />}
    </div>
  )
}

export default PillarsTonye;
