import { SEO } from "@components/seo";
import { globalHistory as history } from "@reach/router";
import { Link, graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { isMobile } from "react-device-detect";
import Marquee from "react-fast-marquee";
import Cursor from "../components/Cursor/cursor";
import LocoNScroll from "../components/LoconativeScroll";
import Section from "../components/_SectionTemplate";
import useWindowSize from "../components/_hooks/useWindowSize";
import TonyeBio from "../components/_pages/home/TonyeBio";
import { PortfolioList } from "../components/_pages/portfolio/PortfolioList";
import Layout from "../components/layout";
import Scroll from "../components/locomotiveScroll";
import PillarsGrid from "../components/pillars/PillarsGrid";
import PillarsLines from "../components/pillars/PillarsLines";
import PillarsTonye from "../components/pillars/PillarsTonye";
import "../styles/pages/_index.scss";
import ContactForm from "./../components/Contact/ContactForm";
import FeaturedWork from "./../components/_pages/home/FeaturedWork";

const IndexPage = ({ data }) => {
  const size = useWindowSize();

  const { location } = history;
  const ref = useRef(null);

  const id = React.useRef(null);

  const [scroll, setScroll] = useState(0);
  const [removePillars, setRemovePillars] = useState(false);
  const [pillarsOpacity, setPillarsOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const LIMIT = 560;
    calculatePillarsOpacity(LIMIT);
    if (scroll > LIMIT) {
      // setRemovePillars(true);
    } else {
      // setRemovePillars(false);
    }
  }, [scroll]);

  const calculatePillarsOpacity = (LIMIT) => {
    const opacity = 1 - scroll / LIMIT;
    // setPillarsOpacity(opacity);
  };

  // useEffect(() => {
  //   console.log(size);
  // }, [size]);

  return (
    <Layout location={location} ref={ref}>
      {!isMobile && <Cursor />}
      {false && <Scroll />}
      {false && <LocoNScroll />}

      <SEO />

      <Container fluid className="home">
        <Section desc="home" hero>
          {false && <PillarsGrid />}
          <div className="row welcome line y3" id="welcome" style={{ opacity: pillarsOpacity }}>
            <div className="h2-wrapper w-100 p-0 line-inner no-delay" data-scroll data-scroll-speed="2.5">
              <h2>
                Creative{" "}
                <Link className="linkzzzz" to="/portfolio">
                  Front-End{/*<span className="emo">👨🏾‍💻</span>*/}
                </Link>
              </h2>
            </div>
          </div>
          <div className="row welcome line y3 mt-0" id="welcome" style={{ opacity: pillarsOpacity }}>
            <div className="h2-wrapper w-100 p-0 line-inner delay-0-3" data-scroll data-scroll-speed="2.5">
              <h2>
                <Link className="linkzzzz" to="/portfolio">
                  Developer {/*<span className="emo">👨🏾‍💻</span>*/}
                </Link>
                <span className="design">& Designer</span>
              </h2>
            </div>
          </div>

          {!removePillars && (
            <b style={{ opacity: pillarsOpacity }}>
              <PillarsTonye text="TONYE" upperCase heavyMode showMask showDiamonds />
            </b>
          )}
          <span className="scroll" data-scroll data-scroll-speed="2">
            <span className="line y3">
              <span className="line-inner">(SCROLL)</span>
            </span>
          </span>
        </Section>
        <Section id="about" desc="about">
          <TonyeBio location={location} />
        </Section>

        {false && (
          <Section desc="featured">
            <FeaturedWork location={location} homepage />
          </Section>
        )}

        {false && (
          <Section desc="pillars">
            {/* <Pillar /> */}
            {false && <PillarsLines />}
          </Section>
        )}
        {/* <Section desc="blog" hero>
          <div
            className="featured"
          >
            <FeaturedWork location={location} homepage />
          </div>
        </Section> */}
        {/* <Section desc="services" hero>
          <TonyeServices />
        </Section> */}

        <PortfolioList home data={data.allMdx.nodes} show={2} />

        {/* <Section desc="music">
          <TonyeMusic />
        </Section> */}

        <Section desc="contact row">
          <Col sm={5}>
            <div className="contact-form-message">
              {false && (
                <Marquee gradient={false} speed={20} className="marquee-content-home" direction="right">
                  Say hello, ask a question about a blog, something broken? Curious about how I built something on my
                  website?
                </Marquee>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <ContactForm />
          </Col>
        </Section>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/portfolio/" }, frontmatter: { category: { eq: "portfolio" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          category
          title
          tags
          date(formatString: "YYYY")
          description
          featuredImage {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1200, height: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 90)
            }
          }
          videoSourceURL {
            publicURL
          }
        }
        id
        slug
      }
    }
  }
`;

export default IndexPage;
