import React from 'react';
import "../../styles/components/_pillarsGrid.scss";

function PillarsGrid() {
  return (
    <div className="pillars-grid">
      <div className="pillar"></div>
      <div className="pillar"></div>
      <div className="pillar"></div>
      <div className="pillar"></div>
      <div className="pillar"></div>
    </div>
  )
}

export default PillarsGrid